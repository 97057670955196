

<template>
	<div class="tcontainer">

		<div class="clearfix ttitle">
			<button v-if="isSuper" @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="title">Manage Tests</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="name" header="Name" filterField="name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['name'] }}
					</div>
				</template>
			</Column>
			<Column field="category" header="Category" filterField="category" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Category</option>

							<option value="single_view">Single View</option>
							<option value="double_view">Double View</option>
							<option value="multi_view">Multi View</option>
							<option value="both_view">Both View</option>
							<option value="other_view">Other View</option>
							<option value="opg">OPG</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['category'] }}
					</div>
				</template>
			</Column>
			<Column header="Options">
				<template #body="{ data }">
					<div v-if="isSuper" class="tdata options">
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
						<button class="btn btn-sm btn-outline-danger" @click="editId = data['id']; deleteModal.show();"><i
								class="fa fa-trash"></i></button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Test</div>
								<div v-else>Edit Test</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<div class="form-group ui-float-label">
								<div class="p-fluid">
									<AutoComplete v-model="testName" placeholder="Test Name" :suggestions="filteredTests"
										@complete="searchTests($event)" field="name" />
								</div>

								<!-- <input type="text" class="form-control" :class="{'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched}" v-model="fdata.name" @blur="fcontrol.name.touched=true" placeholder=" "/>
                            <div class="invalid-feedback" v-if="fcontrol.name?.hasError">
                                <span v-if="fcontrol.name.errors.required">You must enter name</span>
                                <span v-if="fcontrol.name.errors.max">Name can contain maximum {{fcontrol.name.errors.max.maxLength}} characters</span>
                            </div>
                            <label>Name</label> -->
							</div>
							<div class="form-group ui-float-label">
								<select class="form-control"
									:class="{ 'is-invalid': fcontrol.category?.hasError && fcontrol.category.touched }"
									v-model="fdata.category" @blur="fcontrol.category.touched = true">
									<option value="" disabled selected>Select Category</option>
									<option value="single_view">Single View</option>
									<option value="double_view">Double View</option>
									<option value="multi_view">Multi View</option>
									<option value="both_view">Both View</option>
									<option value="other_view">Other View</option>
									<option value="opg">OPG</option>
								</select>
								<div class="invalid-feedback" v-if="fcontrol.category?.hasError">
									<span v-if="fcontrol.category.errors.required">You must enter category</span>
									<span v-if="fcontrol.category.errors.in">Invalid category</span>
								</div>
								<label>Category</label>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import AutoComplete from 'primevue/autocomplete';

export default {
	components: {
		AutoComplete
	},
	data() {
		return {
			loading: false,
			isSuper: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			addModal: null,
			deleteModal: null,
			lazyParams: {},
			testName: '',
			tests: [],
			filteredTests: [],
			filters: {
				'id': { value: '', matchMode: 'like' },
				'name': { value: '', matchMode: 'like' },
				'category': { value: '', matchMode: 'like' },
			},
			fdata: {
				category: '',
			},
			fvalidator: {
				category: 'required|in:single_view,double_view,multi_view,both_view,other_view,opg',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);

		this.post("admin/load-tests", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
		}).then(res => {
			this.tests = res.rows;
		});


		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		putRecord() {
			if(this.fHasError()) console.log("Has error")
			if (this.fHasError() || this.saving) return;
			this.saving = true;

			this.fdata.name = typeof this.testName == 'object' ? this.testName.name : this.testName;
			if (this.editId) this.fdata.id = this.editId;
			this.post("admin/put-test", this.fdata).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		searchTests(e) {
			this.filteredTests = this.tests.filter(t => t.name.toLowerCase().indexOf(e.query.toLowerCase()) != -1);
		},
		searchDoctor(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoctors = [...this.customers];
				} else {
					this.filteredDoctors = this.customers.filter((country) => {
						return country.name.toLowerCase().startsWith(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		showAddEdit(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			this.testName = row ? row : "";
			if (row) this.fpopulate(row);
			this.addModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-test/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("admin/load-tests", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>




